//-------------CONSTANTS---------------
export const FETCHING_PROFILE_DETAILS = 'FETCHING_PROFILE_DETAILS'; // Make sure the constant name matches the import name
export const GETTING_PROFILE_DETAILS = 'GETTING_PROFILE_DETAILS';
export const UPDATING_PROFILE_DETAILS_STATUS =
  'UPDATING_PROFILE_DETAILS_STATUS';
export const PROFILE_UPLOAD_SUCCESS = 'PROFILE_UPLOAD_SUCCESS';
export const IS_PROFILE_UPDATED_STATUS = 'IS_PROFILE_UPDATED_STATUS';

//--------------ACTIONS----------------

export function fetchingProfile(data) {
  return {
    type: FETCHING_PROFILE_DETAILS,
    payload: data,
  };
}
// Profile detail action
export function gettingProfile(data) {
  return {
    type: GETTING_PROFILE_DETAILS,
    payload: data,
  };
}
export function updatingProfile(status) {
  return {
    type: UPDATING_PROFILE_DETAILS_STATUS,
    payload: status,
  };
}
export function gettingProfilePictureUrl(data) {
  return {
    type: PROFILE_UPLOAD_SUCCESS,
    payload: data,
  };
}
export function updateProfileStatus(status) {
  return {
    type: IS_PROFILE_UPDATED_STATUS,
    payload: status,
  };
}
