//-------------CONSTANTS---------------
export const IS_LOGGED_IN_STATUS = 'IS_LOGGED_IN_STATUS';
export const LOGGED_IN_DATA = 'LOGGED_IN_DATA';
export const IS_OTP_MODAL_OPEN = 'IS_OTP_MODAL_OPEN';
export const IS_VALIDATE_OTP = 'IS_VALIDATE_OTP';
export const VALIDATE_OTP_RES = 'VALIDATE_OTP_RES';
export const FETCHING_VALIDATE_OTP = 'FETCHING_VALIDATE_OTP';
export const FIRST_TIME_USER = 'FIRST_TIME_USER';
export const OTP_ERROR_RES = 'OTP_ERROR_RES';

//--------------ACTIONS----------------

export function fetchingLoggedIn(status) {
  return {
    type: IS_LOGGED_IN_STATUS,
    payload: status,
  };
}
export function gettingLoggedInData(data) {
  return {
    type: LOGGED_IN_DATA,
    payload: data,
  };
}
export function isOtpModal(status) {
  return {
    type: IS_OTP_MODAL_OPEN,
    payload: status,
  };
}
export function fetchingValidateOtp(status) {
  return {
    type: IS_VALIDATE_OTP,
    payload: status,
  };
}
export function validateOtp(data) {
  return {
    type: VALIDATE_OTP_RES,
    payload: data,
  };
}
export function fetchingResendOtp(status) {
  return {
    type: FETCHING_VALIDATE_OTP,
    payload: status,
  };
}
export function firstTime(status) {
  return {
    type: FIRST_TIME_USER,
    payload: status,
  };
}
export function otpError(data) {
  return {
    type: OTP_ERROR_RES,
    payload: data,
  };
}
