//-------------CONSTANTS---------------
export const COUNTER = 'COUNTER';

//--------------ACTIONS----------------

export function upDateCounter(data) {
  return {
    type: COUNTER,
    payload: data,
  };
}
