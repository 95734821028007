//-------------CONSTANTS---------------
export const PROPERTYLIST = 'PROPERTYLIST'; // Make sure the constant name matches the import name
export const PROPERTY_DETAIL = 'PROPERTY_DETAIL';
export const AVAILABILITY_CALENDER_DATES = 'AVAILABILITY_CALENDER_DATES';
export const FETCHING_UPDATED_PRICING = 'FETCHING_UPDATED_PRICING';
export const GETTING_UPDATED_PRICING = 'GETTING_UPDATED_PRICING';
export const FETCHING_BOOKING_STATUS = 'FETCHING_BOOKING_STATUS';
export const GETTING_BOOKING = 'GETTING_BOOKING';
export const COPYING_AVAILABILITY_CALENDER_DATES =
  'COPYING_AVAILABILITY_CALENDER_DATES';
export const PAGES_DETAILS_SECTION_DATA = 'PAGES_DETAILS_SECTION_DATA';
export const PAGES_FOOTER_SECTION_DATA = 'PAGES_FOOTER_SECTION_DATA';
export const GET_CITY_LIST = 'GET_CITY_LIST';
export const GET_MINIMUM_STAYS = 'GET_MINIMUM_STAYS';
//--------------ACTIONS----------------

export function updatePropertyList(data, loading = false) {
  return {
    type: PROPERTYLIST,
    payload: data,
    isLoading: loading,
  };
}
// property detail action
export function propertyDetails(data) {
  return {
    type: PROPERTY_DETAIL,
    payload: data,
  };
}
// Availability Calender Dates action
export function availableDates(data) {
  return {
    type: AVAILABILITY_CALENDER_DATES,
    payload: data,
  };
}
//  UPDATING PRICING
export function fetchingUpdatedPricing(status) {
  return {
    type: FETCHING_UPDATED_PRICING,
    payload: status,
  };
}
export function gettingUpdatedPricingRes(data) {
  return {
    type: GETTING_UPDATED_PRICING,
    payload: data,
  };
}
//  BOOKING
export function bookingStatus(status) {
  return {
    type: FETCHING_BOOKING_STATUS,
    payload: status,
  };
}
export function gettingBookingRes(data) {
  return {
    type: GETTING_BOOKING,
    payload: data,
  };
}
export function copyAvailableDates(data) {
  return {
    type: COPYING_AVAILABILITY_CALENDER_DATES,
    payload: data,
  };
}
export function pagesDetailsData(data) {
  return {
    type: PAGES_DETAILS_SECTION_DATA,
    payload: data,
  };
}
export function pagesFooterData(data) {
  return {
    type: PAGES_FOOTER_SECTION_DATA,
    payload: data,
  };
}
export function getCityList(data) {
  return {
    type: GET_CITY_LIST,
    payload: data,
  };
}
export function getMinimumStays(data) {
  return {
    type: GET_MINIMUM_STAYS,
    payload: data,
  };
}
