//-------------CONSTANTS---------------
export const LOADING = 'LOADING'; // Make sure the constant name matches the import name
export const FETCHING_REVIEW_BOOKING_STATUS = 'FETCHING_REVIEW_BOOKING_STATUS';
export const GETTING_REVIEW_BOOKING_DATA = 'GETTING_REVIEW_BOOKING_DATA';
export const GETTING_USER_ID = 'GETTING_USER_ID';
export const GET_CONFIRMATION_DETAILS = 'GET_CONFIRMATION_DETAILS';
export const FETCHING_BOOKING_DETAILS_STATUS =
  'FETCHING_BOOKING_DETAILS_STATUS';
export const GETTING_BOOKING_DETAILS = 'GETTING_BOOKING_DETAILS';
export const FETCHING_BOOKING_DETAILS_CONTENT_STATUS =
  'FETCHING_BOOKING_DETAILS_CONTENT_STATUS';
export const GETTING_BOOKING_DETAILS_CONTENT_DATA =
  'GETTING_BOOKING_DETAILS_CONTENT_DATA';
export const FETCHING_BOOKING_LISTING_STATUS =
  'FETCHING_BOOKING_LISTING_STATUS';
export const GETTING_BOOKING_LISTING_DATA = 'GETTING_BOOKING_LISTING_DATA';
export const GETTING_BOOKING_DETAILS_ERROR_STATUS =
  'GETTING_BOOKING_DETAILS_ERROR_STATUS';
export const FETCHING_PARTIAL_PAYMENT_TOGGLE =
  'FETCHING_PARTIAL_PAYMENT_TOGGLE';
//--------------ACTIONS----------------

export function fetchBookingDetails(data) {
  return {
    type: LOADING,
    payload: data,
  };
}
export function fetchingReviewBookingStatus(status) {
  return {
    type: FETCHING_REVIEW_BOOKING_STATUS,
    payload: status,
  };
}
export function gettingReviewBookingData(data) {
  return {
    type: GETTING_REVIEW_BOOKING_DATA,
    payload: data,
  };
}
export function gettingUserIdData(data) {
  return {
    type: GETTING_USER_ID,
    payload: data,
  };
}
export function getConfimationDetails(data) {
  return {
    type: GET_CONFIRMATION_DETAILS,
    payload: data,
  };
}
export function fetchBookingDetailsStatus(status) {
  return {
    type: FETCHING_BOOKING_DETAILS_STATUS,
    payload: status,
  };
}
export function getBookingDetails(data) {
  return {
    type: GETTING_BOOKING_DETAILS,
    payload: data,
  };
}
export function fetchBookingDetailsContentStatus(status) {
  return {
    type: FETCHING_BOOKING_DETAILS_CONTENT_STATUS,
    payload: status,
  };
}
export function getBookingDetailsContentData(data) {
  return {
    type: GETTING_BOOKING_DETAILS_CONTENT_DATA,
    payload: data,
  };
}
export function fetchingBookingListing(status) {
  return {
    type: FETCHING_BOOKING_LISTING_STATUS,
    payload: status,
  };
}
export function gettingBookingListing(data) {
  return {
    type: GETTING_BOOKING_LISTING_DATA,
    payload: data,
  };
}
export function gettingBookingDetailsError(data) {
  return {
    type: GETTING_BOOKING_DETAILS_ERROR_STATUS,
    payload: data,
  };
}
export function fetchingPartialPaymentToggle(status) {
  return {
    type: FETCHING_PARTIAL_PAYMENT_TOGGLE,
    payload: status,
  };
}
