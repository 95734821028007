//-------------CONSTANTS---------------
export const WALLET_DETAIL_LOADING = 'LOADING'; // Make sure the constant name matches the import name
export const GETTING_WALLET_DETAIL_DATA = 'GETTING_WALLET_DETAIL_DATA';
export const GETTING_FAQ = 'GETTING_FAQ';
export const GETTING_FAQ_LOADING = 'GETTING_FAQ_LOADING';

export const GETTING_WALLET_LIST = 'GETTING_WALLET_LIST'; // Make sure the constant name matches the import name
export const GETTING_WALLET_LIST_LOADING = 'GETTING_WALLET_LIST_LOADING';
//--------------ACTIONS----------------

export function fetchingWalletDetail(status) {
  return {
    type: WALLET_DETAIL_LOADING,
    payload: status,
  };
}
export function gettingWalletDetail(data) {
  return {
    type: GETTING_WALLET_DETAIL_DATA,
    payload: data,
  };
}

export function gettingFaqsLoading(status) {
  return {
    type: GETTING_FAQ_LOADING,
    payload: status,
  };
}

export function gettingFaqs(data) {
  return {
    type: GETTING_FAQ,
    payload: data,
  };
}

export function gettingWalletList(data) {
  return {
    type: GETTING_WALLET_LIST,
    payload: data,
  };
}
export function gettingWalletListLoading(status) {
  return {
    type: GETTING_WALLET_LIST_LOADING,
    payload: status,
  };
}
