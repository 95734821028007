// App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import generateRoute from './routes/index';
import appRoutes from './routes/appRoutes';

function App() {
  return <Router>{generateRoute(appRoutes)}</Router>;
}

export default App;
